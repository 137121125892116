import React from 'react'
import {Link} from 'gatsby'
import img4 from '../../assets/images/blog/blog-img4.jpg'
import img5 from '../../assets/images/blog/blog-img5.jpg'
import img6 from '../../assets/images/blog/blog-img6.jpg'
import img7 from '../../assets/images/blog/blog-img7.jpg'
import img8 from '../../assets/images/blog/blog-img8.jpg'
import img10 from '../../assets/images/blog/blog-img10.jpg'
import user1 from '../../assets/images/user1.jpg'
import user2 from '../../assets/images/user2.jpg'
import user3 from '../../assets/images/user3.jpg'
import user4 from '../../assets/images/user4.jpg'
import user5 from '../../assets/images/user5.jpg'
import user6 from '../../assets/images/user6.jpg'

const BlogCard = () => {
    return (
        <div className="blog-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post bg-fffbf5">
                            <div className="post-image">
                                <Link to="/blog/5-motive-pentru-care-afacarea-software-personalizat/">
                                    <img src={img4} alt="image" />
                                </Link>
                            </div>

                            <div className="post-content">
                                <ul className="post-meta d-flex justify-content-between align-items-center">
                                    <li>
                                         Aprilie 30, 2022
                                    </li>
                                </ul>
                                <h3>
                                    <Link to="/blog/5-motive-pentru-care-afacarea-software-personalizat/">
                                         5 motive pentru care afacerea ta are nevoie de software personalizat
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post bg-fffbf5">
                            <div className="post-image">
                                <Link to="/blog/provocari-transformare-digitala/">
                                    <img src={img5} alt="image" />
                                </Link>
                            </div>

                            <div className="post-content">
                                <ul className="post-meta d-flex justify-content-between align-items-center">
                                    <li>
                                         Mai 28, 2022
                                    </li>
                                </ul>
                                <h3>
                                    <Link to="/blog/provocari-transformare-digitala/">
                                        Provocări și capcane ale inițiativelor de transformare digitală
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post bg-fffbf5">
                            <div className="post-image">
                                <Link to="/blog/strategie-transformare-digitala/">
                                    <img src={img6} alt="image" />
                                </Link>
                            </div>

                            <div className="post-content">
                                <ul className="post-meta d-flex justify-content-between align-items-center">
                                    <li>
                                         Iunie 29, 2022
                                    </li>
                                </ul>
                                <h3>
                                    <Link to="/blog/strategie-transformare-digitala/">
                                       Cum să creezi o strategie de transformare digitală pentru afacerea ta
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BlogCard